<!-- =========================================================================================
    File Name: ForgotPassword.vue
    Description: FOrgot Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="h-screen flex w-full bg-img">
    <div
      class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center"
    >
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div
              class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center"
            >
              <img
                src="@/Core/assets/images/pages/forgot-password.png"
                alt="login"
                class="mx-auto"
              />
            </div>
            <div
              class="vx-col cust-pd-top sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg"
            >
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Recover your password</h4>
                  <p v-show="!verifyOTP">Please enter your mobile number.</p>
                </div>

                <vs-input
                  v-show="!verifyOTP"
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  name="mobile"
                  type="tel"
                  label-placeholder="Mobile"
                  v-model="mobile"
                  class="w-full mb-8"
                />
                <span class="text-danger text-sm">
                  {{ errors.first("mobile") }}
                </span>

                <div class="justify-between">
                  <div class="w-1/2">
                    <vs-button
                      class="float-left mb-8 mr-3 whitespace-no-wrap"
                      v-show="phoneVerified && !verifyOTP"
                      @click="generateOTP"
                      :disabled="mobile == null"
                    >
                      {{
                        isOTPGenerated ? "Regenerate OTP" : "Generate OTP"
                      }}</vs-button
                    >
                  </div>
                  <div class="">
                    <vs-input
                      v-show="!verifyOTP"
                      type="password"
                      v-validate="'min:6|max:6'"
                      name="opt"
                      label-placeholder="OTP"
                      placeholder="OTP"
                      v-model="otp"
                      v-if="phoneVerified"
                      class="mb-8 w-full pos-unset"
                    />
                  </div>
                </div>
                <div class="w-full">
                  <vs-input
                    ref="password"
                    type="password"
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    name="password"
                    label-placeholder="Password"
                    placeholder="Password"
                    v-model="password"
                    class="w-full mt-6"
                    v-show="verifyOTP"
                  />
                  <span class="text-danger text-sm d-block">
                    {{ errors.first("password") }}
                  </span>

                  <vs-input
                    type="password"
                    v-validate="'required|confirmed:password'"
                    data-vv-validate-on="blur"
                    data-vv-as="password"
                    name="confirm_password"
                    label-placeholder="Confirm Password"
                    placeholder="Confirm Password"
                    v-model="confirm_password"
                    class="w-full mt-6 mb-4"
                    v-show="verifyOTP"
                  />
                  <span class="text-danger text-sm">
                    {{ errors.first("confirm_password") }}
                  </span>
                </div>
                <div class="mt-4 flex w-full justify-between">
                  <vs-button
                    type="border"
                    to="/pages/customadminlogin"
                    class="px-4 w-full md:w-auto float-left"
                    >Back To Login</vs-button
                  >

                  <!-- <vs-button class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-0">Generate OTP</vs-button> -->
                  <vs-button
                    class="float-right px-4 w-full md:w-auto"
                    v-show="!phoneVerified"
                    @click="checkPhoneNumber"
                    :disabled="mobile == null"
                    >Verify Mobile Number</vs-button
                  >

                  <vs-button
                    class="float-right px-4 w-full md:w-auto"
                    v-show="phoneVerified"
                    v-if="verified && !verifyOTP"
                    @click="checkOTP"
                    :disabled="!validateForm"
                    >Verify OTP</vs-button
                  >

                  <vs-button
                    class="float-right px-4 w-full md:w-auto"
                    v-show="verifyOTP"
                    @click="changePassword"
                    :disabled="!validatePasswords"
                    >Change Password</vs-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
//import axios from "axios";

import { currentCommunityName } from "@/Core/config/communityDetails.js";

import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";

const userRepository = RepositoryFactory.get("user");

export default {
  data() {
    return {
      mobile: null,
      otp: null,
      phoneVerified: false,
      verified: false,
      generatedOTP: 0,
      verifyOTP: false,
      password: "",
      confirm_password: "",
      userId: null,
      isOTPGenerated: false,
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.mobile != null;
    },
    validatePasswords() {
      return (
        !this.errors.any() && this.password != "" && this.confirm_password != ""
      );
    },
  },
  methods: {
    async checkOTP() {
      this.$vs.loading();
      const payload = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        mobile: this.mobile,
        otp: this.otp,
      };
      const { data } = await userRepository.checkOTP(payload);

      if (data.ResponseCode == 101) {
        this.$vs.loading.close();
        this.verifyOTP = true;
      } else if (data.ResponseCode == 102) {
        this.$vs.loading.close();
        window.alert("Incorrect OTP");
        this.verified = false;
        this.otp = null;
      } else {
        this.$vs.loading.close();
        this.verified = false;
        this.otp = null;
      }
    },
    async checkPhoneNumber() {
      let self = this;
      this.$vs.loading();
      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        phoneNumber: this.mobile,
      };
      const { data } = await userRepository.checkPhoneNumber(obj);

      if (data.length > 0) {
        self.$vs.loading.close();
        self.phoneVerified = true;
        self.userId = data[0].usersDetailsId;
      } else {
        self.$vs.loading.close();
        self.$vs.notify({
          title: "Error",
          text: "User with this Phone number does not exist",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      }
    },
    async generateOTP() {
      this.$vs.loading();
      this.isOTPGenerated = true;
      const payload = {
        mobile: this.mobile,
        communityData: {
          communityName: currentCommunityName.communityName,
        },
      };
      const { data } = await userRepository.sendOTP(payload);

      if (data) {
        this.$vs.loading.close();
        this.verified = true;
        // this.$vs.notify({
        //   time: 8000,
        //   title: "OTP",
        //   text: data.OTP,
        //   iconPack: "feather",
        //   icon: "icon-alert-circle",
        //   color: "success"
        // });
      } else {
        this.$vs.loading.close();
      }
      // this.generatedOTP = this.getRandomInt(100000, 999999);
      // this.verified = true;
      // console.log(this.generatedOTP, this.verified);
    },
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    async changePassword() {
      let self = this;
      this.$vs.loading();

      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        id: this.userId,
        password: this.password,
        mobile: this.mobile,
      };

      const { data } = await userRepository.changePassword(obj);

      if (data.data.ResponseCode == 100) {
        self.$vs.loading.close();
        localStorage.setItem("token", data.token);
        self.$router.push("/community-home").catch(() => {});
      } else {
        self.$vs.loading.close();
        self.$vs.notify({
          title: "Error",
          text: "An Error Occured. Try Again",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      }
    },
  },
};
</script>
